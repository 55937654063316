import { FC } from 'react'
import dynamic from 'next/dynamic'
import { isTenant } from '@/utils/config'
import { GetServerSidePropsContext } from 'next'
import { getUserProfile } from '@/modules/core/services/membership.service'
import { HomeData, fetchHomeData } from '@/services/contentful/homepage/homepage'

const TKHomePage = dynamic(() => import('@/components/Homepage/tk').then((m) => m.default), { ssr: true })
const WWHomePage = dynamic(() => import('@/components/Homepage/ww').then((m) => m.default), { ssr: true })

export type HomePageProps = {
  bannerData: HomeData
}

const HomePage: FC<HomePageProps> = (props) => (isTenant('tk') ? <TKHomePage {...props} /> : <WWHomePage {...props} />)

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const res = ctx.res

  // https://nextjs.org/docs/pages/building-your-application/data-fetching/get-server-side-props#caching-with-server-side-rendering-ssr
  if (process.env.UNION_DEPLOYMENT_ENV === 'qa') {
    res.setHeader('Cache-Control', 'public, max-age=86400, immutable')
  }

  const userProfile = await getUserProfile(ctx)

  const bannerData = await fetchHomeData()

  return {
    props: {
      bannerData: bannerData,
      userProfile,
    },
  }
}

export default HomePage
